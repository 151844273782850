import { useState, useRef } from 'react';
import {
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Alert,
  Stack,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiDelete } from 'react-icons/fi';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function TabClosing({ onClose, dataChiusura, id, setRefresh }) {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dateRef = useRef();

  const clean = () => {
    dateRef.current.value = '';
  };

  const onSave = async () => {
    setError(false);
    setIsLoading(true);
    const controller = new AbortController();
    console.log(
      JSON.stringify({
        dataChiusura: dateRef.current.value,
        id: id,
      })
    );
    try {
      const response = await axiosPrivate.post(
        `/api/set-closing.php?id=${id}`,
        JSON.stringify({
          dataChiusura: dateRef.current.value,
        }),
        {
          signal: controller.signal,
        }
      );
      console.log(response.data);
      onClose();
      setRefresh({});
    } catch (err) {
      if (err.response.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      setIsLoading(false);
      setError(true);
    }
  };

  return (
    <>
      <ModalBody>
        <Stack width="full" spacing="6" align="center">
          <HStack align="end" w="2xs">
            <FormControl id="Data">
              <FormLabel>Data Chiusura</FormLabel>
              <Input
                type="date"
                defaultValue={dataChiusura}
                ref={dateRef}
                min="1920-01-01"
                max={new Date().toISOString().slice(0, 10)}
              />
            </FormControl>
            <IconButton
              icon={<FiDelete />}
              variant="ghost"
              onClick={clean}
              title="Cancella Campo Data"
            />
          </HStack>
        </Stack>
      </ModalBody>

      <ModalFooter mt="6">
        <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
          Annulla
        </Button>
        <Button variant="outline" onClick={onSave} isLoading={isLoading}>
          Salva
        </Button>
      </ModalFooter>
      {error && <Alert status="error">Errore</Alert>}
    </>
  );
}

export default TabClosing;
