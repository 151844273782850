import {
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
import { useState } from 'react';
//import SetAssignClose from './SetAssignClose';

import { columns } from './_data';
import { DeleteButton } from './DeleteButton';

/* import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi'; */
import Actions from './Actions';

export const TableContent = ({
  data,
  setRefresh,
  isLoading,
  setParams,
  params,
  isAdmin,
}) => {
  const [deleteProps, setDeleteProps] = useState({});

  const openDeleteButton = (id, numSx) => {
    setDeleteProps({ id, numSx });
    onOpen();
  };

  /* const navigate = useNavigate(); */
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <>
      <Table my="8" borderWidth="1px" fontSize="sm" fontWeight="450">
        <Thead bg={mode('gray.50', 'gray.800')}>
          <Tr>
            {columns.map((column, index) => (
              <Th
                whiteSpace="nowrap"
                scope="col"
                key={index}
                px="10px"
                cursor="pointer"
                textDecoration={
                  params.orderBy === column.accessor ? 'underline' : 'none'
                }
                onClick={() => {
                  setParams(prev => ({
                    ...prev,
                    orderBy: column.accessor,
                    noDesc: prev.noDesc ? '' : 'noDesc',
                    offset: '',
                  }));
                }}
              >
                {column.Header}
              </Th>
            ))}
            <Th px="10px" />
            {/* <Th /> */}
          </Tr>
        </Thead>
        <Tbody>
          {
            /* !isLoading && */
            data.map((row, index) => (
              <Tr key={index} _hover={{ bg: 'var(--chakra-colors-bg-canvas)' }}>
                {columns.map((column, index) => {
                  const cell = row[column.accessor];
                  const [element, title] = column.Cell?.(row) ?? [cell];
                  return (
                    // <Td whiteSpace="nowrap" key={index}>

                    <Td whiteSpace="nowrap" key={index} px="10px" title={title}>
                      {element}
                    </Td>
                  );
                })}
                {/*  <Td textAlign="right">
              <Button variant="link" colorScheme="blue">
                Edit
              </Button>
            </Td> */}
                <Td textAlign="left" px="0px">
                  {/*   <IconButton
                verticalAlign="middle"
                size="md"
                variant="link"
                colorScheme="blue"
                icon={<FiMenu />}
                onClick={() => navigate(`/${row.numSx}`)}
              >
              </IconButton> */}
                  <Actions
                    numSx={row.numSx}
                    /* onOpenAssign={onOpenAssign} */ nomePerito={
                      row.nomePerito
                    }
                    dataIncarico={row.dataIncarico}
                    dataChiusura={row.dataChiusura}
                    id={row.id}
                    setRefresh={setRefresh}
                    openDeleteButton={openDeleteButton}
                    isAdmin={isAdmin}
                  />
                </Td>
              </Tr>
            ))
          }
          {/* {isLoading &&
            [...Array(12)].map(a => {
              return (
                <Tr>
                  <Skeleton h="53px" />
                </Tr>
              );
            })} */}
          {data.length && data.length < 12 && !isLoading
            ? [...Array(12 - data.length)].map((a, i) => {
                return <Tr key={i} h="53px"></Tr>;
              })
            : null}

          {!data.length &&
            [...Array(12)].map((a, i) => {
              return <Tr key={i} h="53px"></Tr>;
            })}
        </Tbody>
      </Table>

      {/* {!data.length && <Text fontSize="md">Nessun Risultato Da Mostrare</Text>} */}

      <DeleteButton
        onClose={onClose}
        isOpen={isOpen}
        deleteProps={deleteProps}
        setRefresh={setRefresh}
      />
    </>
  );
};
