import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseButton } from './CloseButton';
import { useEffect } from 'react';
import { PDFObject } from 'react-pdfobject';

const FullImage = ({ onClose, url }) => {
  useEffect(() => {
    window.scrollTo(0, (document.body.clientHeight - window.innerHeight) / 2);
  }, []);

  return (
    <Box position="relative">
      <AspectRatio ratio={4 / 3}>
        {/* <LinkOverlay href="#"> */}

        {/* <Image
          src={`${process.env.REACT_APP_BASE_URL}${url}`}
          alt={'name'}
          draggable="false"
          fallback={<Skeleton />}
          borderRadius={useBreakpointValue({
            base: 'md',
            md: 'xl',
          })}
        /> */}

        <PDFObject
          url={`${process.env.REACT_APP_BASE_URL}${url}`}
          pdfOpenParams={{ view: 'FitH' }}
          overscrollBehavior="contain"
        />

        {/* </LinkOverlay> */}
      </AspectRatio>
      <CloseButton position="absolute" top="3" right="4" onClose={onClose} />
    </Box>
  );
};

export default FullImage;
