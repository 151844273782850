import {
  Alert,
  Box,
  useDisclosure,
  Divider,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { ProductCard } from './ProductCard';
import { products } from './_data';
import { ProductGrid } from './ProductGrid';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Upload from './Upload';
import FullImage from './FullImage';

export const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState(null);
  const { auth } = useAuth();

  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState({});
  const [error, setError] = useState('');

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const { numSx } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    const getImages = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/read-images.php?numsx=${numSx}`,
          {
            signal: controller.signal,
          }
        );
        console.log(response.data);

        setImages(
          response.data.filter(el => el.name !== '.' && el.name !== '..')
        );
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 403) {
          navigate('/login', { state: { from: location }, replace: true });
        }
        if (err.response.status === 404) {
          //setError('404, La Pagina Richiesta non esiste ');
          navigate('/errore404', { replace: true });
        } else {
          setError('Errore, Riprova');
        }

        setIsLoading(false);
      }
    };

    getImages();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      {error && <Alert status="error">{error}</Alert>}
      {!isOpen && (
        <Upload
          images={images}
          setRefresh={setRefresh}
          numSx={numSx}
          isAdmin={auth.role === 'admin'}
        />
      )}

      {!isOpen && (
        <Stack spacing={3}>
          <Divider />
          {images?.length === 0 && (
            <Text fontSize="lg">Ancora Nessuna Foto</Text>
          )}
          <ProductGrid>
            {images?.map(image => (
              <ProductCard
                isAdmin={auth.role === 'admin'}
                key={image.id}
                image={image}
                onOpen={onOpen}
                setUrl={setUrl}
                setRefresh={setRefresh}
              />
            ))}
          </ProductGrid>
        </Stack>
      )}
      {isOpen && <FullImage onClose={onClose} url={url} />}
    </Box>
  );
};
