import {
  ButtonGroup,
  Container,
  IconButton,
  Stack,
  Text,
  Heading,
  HStack,
  VStack,
  Image,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  FaGithub,
  FaLinkedin,
  FaTwitter,
  FaRegEnvelope,
  FaAt,
} from 'react-icons/fa';
import { FiAtSign, FiGlobe, FiMail, FiInfo } from 'react-icons/fi';

import { Logo } from './Logo';

export const App = () => (
  <Container
    as="footer"
    role="contentinfo"
    py={{
      base: '4',
      md: '4',
    }}
    sx={{
      '@media print': {
        display: 'none',
      },
    }}
  >
    <Stack
      /*  spacing={{
        base: '2',
        md: '2',
      }} */
      spacing="0"
    >
      <Stack justify="space-between" direction="row" align="center">
        <HStack>
          <Image
            borderRadius="full"
            h="45px"
            //src="/logo-citta-campobasso.png"
            src={process.env.REACT_APP_LOGO}
          />
          <VStack spacing="0" align="left">
            <Text fontSize="sm">Centro Liquidazione Danni</Text>
            <Text fontSize="sm" color="subtle" mt="0">
              Loss Adjusting Service - {process.env.REACT_APP_ENTE}
            </Text>
          </VStack>
        </HStack>
        {/* <Logo /> */}

        {/* <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="mailto:cldbenevento@gmail.com"
            aria-label="info"
            icon={<FiInfo fontSize="1.25rem" />}
            title="info"
            color="subtle"
          />
          <IconButton
            as="a"
            href="mailto:cldbenevento@gmail.com"
            aria-label="Email"
            title="Email"
            icon={<FiMail fontSize="1.25rem" />}
            color="subtle"
          />
        </ButtonGroup> */}

        <HStack>
          {/* <Image borderRadius="full" h="45px" src="logo-citta-campobasso.png" /> */}
          <VStack spacing="0" align="end">
            <Text fontSize="sm" color="subtle">
              0824 24539
            </Text>
            <Text fontSize="sm" color="subtle" mt="0">
              cldbenevento@gmail.com
            </Text>
          </VStack>
        </HStack>
      </Stack>
    </Stack>
  </Container>
);
{
  /* &copy; {new Date().getFullYear()} Chakra UI Pro, Inc. All rights
        reserved. */
}
