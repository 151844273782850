import { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Alert,
  Stack,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiDelete } from 'react-icons/fi';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function SetNew({ isOpen, onClose }) {
  const axiosPrivate = useAxiosPrivate();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const nameRef = useRef();

  const clean = () => {
    nameRef.current.value = '';
  };

  /* useEffect(() => {
    nameRef.current.focus();
  }, []); */

  const onSave = async () => {
    setError('');
    setIsLoading(true);
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.post(
        `/api/create.php`,
        JSON.stringify({
          numSx: nameRef.current.value,
        }),
        {
          signal: controller.signal,
        }
      );
      console.log(response.data);
      setIsLoading(false);
      navigate(`/${nameRef.current.value}`, {
        state: { nonEditable: false },
      });
      //onClose();
    } catch (err) {
      if (err.response.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      if (err.response.status === 412) {
        setError('Numero sinistro già esistente, sceglierne uno differente');
      } else {
        setError('Errore, Riprova');
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={nameRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mb="3">Nuovo Sinistro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width="full" spacing="6" align="center">
              <HStack align="end" w="2xs">
                <FormControl id="name" maxW="2xs">
                  <FormLabel>Numero</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    autoComplete="off"
                    ref={nameRef}
                    defaultValue={`${
                      process.env.REACT_APP_SIGLA
                    }${new Date().getFullYear()}`}
                    onChange={e => {
                      e.target.value.length > 8
                        ? setIsDisabled(false)
                        : setIsDisabled(true);
                    }}
                  />
                </FormControl>
                <IconButton
                  icon={<FiDelete />}
                  variant="ghost"
                  title="Cancella"
                  onClick={clean}
                />
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter mt="6">
            <Button
              variant="ghost"
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClose();
                setError('');
              }}
            >
              Annulla
            </Button>
            <Button
              variant="outline"
              onClick={onSave}
              isLoading={isLoading}
              isDisabled={isDisabled}
              title={isDisabled && 'Formato numero sinistro non conforme'}
            >
              Salva
            </Button>
          </ModalFooter>
          {error && <Alert status="error">{error}</Alert>}
        </ModalContent>
      </Modal>
    </>
  );
}

export default SetNew;
