import {
  Button,
  ButtonGroup,
  Flex,
  Text,
  Select,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { data } from './_data';
import { FiChevronsLeft, FiChevronsRight, FiList } from 'react-icons/fi';

export const TablePagination = ({
  setParams,
  isLoading,
  dataLength,
  offset,
}) => {
  const [limit, setLimit] = useState(localStorage.getItem('perPageCB') || '12');

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      limit,
    }));
  }, [limit]);

  return (
    <Flex align="center" justify="space-between">
      {/* <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
        {data.length} members
      </Text> */}
      <Select
        /* w={{
          base: '300px',
          md: 'unset',
        }} */
        icon={<FiList />}
        w="unset"
        rounded="base"
        size="sm"
        defaultValue={limit}
        title="Risultati Per Pagina"
        isDisabled={dataLength < limit}
        onChange={e => {
          localStorage.setItem('perPageCB', e.target.value);
          setLimit(e.target.value);
          window.scrollTo(0, 0);
        }}
      >
        <option value="12">12</option>
        <option value="24">24</option>
        <option value="36">36</option>
      </Select>

      <ButtonGroup variant="outline" size="sm">
        <Button
          //as="a"
          rel="prev"
          isDisabled={+offset === 0 || isLoading}
          leftIcon={<FiChevronsLeft />}
          onClick={() =>
            setParams(prev => ({
              ...prev,
              offset: +prev.offset - limit,
            }))
          }
        >
          Indietro
        </Button>
        <Button
          //as="a"
          rel="next"
          isDisabled={dataLength < limit || isLoading}
          rightIcon={<FiChevronsRight />}
          onClick={() => {
            setParams(prev => ({
              ...prev,
              offset: +prev.offset + +limit,
            }));
            window.scrollTo(0, 0);
          }}
        >
          Avanti
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
