import {
  Button,
  IconButton,
  ButtonGroup,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { groups } from '../../groups';
import { BsSearch } from 'react-icons/bs';
import { RiAddFill, RiArrowRightUpLine } from 'react-icons/ri';
import { FiArrowDown, FiArrowUp, FiRefreshCw } from 'react-icons/fi';
import SetNew from './SetNew';

export const TableActions = ({ isAdmin, isLoading, setParams }) => {
  const [sub, setSub] = useState({ search: '', group: '' });
  const searchRef = useRef();
  const groupRef = useRef();

  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <>
      <Stack
        //spacing="4"
        spacing={{
          base: '4',
          md: '2',
        }}
        direction={{
          base: 'column',
          md: 'row',
        }}
        //justify="space-between"
      >
        <HStack>
          <FormControl
            maxW={{
              md: '220px',
            }}
            id="search"
          >
            <InputGroup size="sm">
              <FormLabel srOnly>Filter by name or email</FormLabel>

              <Input
                rounded="base"
                type="search"
                ref={searchRef}
                placeholder="nome,luogo,targa,CF,auto... "
                autoComplete="off"
              />
            </InputGroup>
          </FormControl>
          <Button
            size="sm"
            rounded="base"
            variant="solid"
            isLoading={isLoading}
            onClick={() => {
              setParams(prev => ({
                ...prev,
                search: searchRef.current.value.trim().replace(/\s+/g, ' '),
                group: groupRef.current.value,
                offset: '',
                noDesc: '',
                orderBy: '',
              }));
            }}
          >
            Cerca
          </Button>
          <Select
            w={{
              base: '300px',
              md: 'unset',
            }}
            rounded="base"
            ref={groupRef}
            size="sm"
            placeholder="Tutti"
          >
            {groups.map(group => {
              return (
                <option key={group.id_groups} value={group.id_groups}>
                  {group.name}
                </option>
              );
            })}
          </Select>
        </HStack>
        <Flex grow="1">
          <HStack>
            {/*  <IconButton
              size="sm"
              rounded="base"
              variant="solid"
              icon={<FiArrowDown />}
            ></IconButton> */}
            <IconButton
              size="sm"
              rounded="base"
              variant="solid"
              icon={<FiRefreshCw />}
              title="Reset Ricerca"
              onClick={() => {
                setParams(prev => ({
                  ...prev,
                  group: '',
                  orderBy: '',
                  noDesc: '',
                  search: '',
                  offset: '',
                }));
                searchRef.current.value = '';
                groupRef.current.value = '';
              }}
            ></IconButton>
          </HStack>
          <Spacer />
          <ButtonGroup size="sm" variant="outline">
            {isAdmin && (
              <Button
                iconSpacing="1"
                leftIcon={<RiAddFill fontSize="1.25em" />}
                onClick={onOpen}
              >
                Nuovo
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </Stack>

      <SetNew isOpen={isOpen} onClose={onClose} />
    </>
  );
};
