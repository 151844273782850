import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Select,
  Heading,
  HStack,
  Flex,
  Spacer,
  Input,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useColorModeValue,
  VStack,
  Switch,
  Badge,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react';

import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { HiCloudUpload } from 'react-icons/hi';
import { FaGithub, FaGoogle } from 'react-icons/fa';
import { RiArrowRightUpLine } from 'react-icons/ri';
import { FieldGroup } from './FieldGroup';
import { FeritoGroup } from './FeritoGroup';
import CF from './CF';
import GroupsBadge from './GroupsBadge';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { initialValues } from './initialValues';
import { groups, res } from './groups';
import { Printd } from 'printd';

export const Stampa = () => {
  const { numSx } = useParams();
  const [values, setValues] = useState(initialValues);
  const [nonEditable, setNonEditable] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenGroups,
    onOpen: onOpenGroups,
    onClose: onCloseGroups,
  } = useDisclosure();

  useEffect(() => {
    const controller = new AbortController();

    const getValues = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/single_read.php?id=1176`,
          {
            signal: controller.signal,
          }
        );
        console.log(response.data);

        setValues(response.data);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getValues();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      py="16"
      maxWidth="4xl"
      mx="auto"
    >
      <form id="settings-form" autoComplete="off">
        <Stack spacing="4" divider={<StackDivider />}>
          <Stack>
            <HStack justify="space-between" align="center">
              <Heading size="sm" as="h3" paddingBottom="3">
                Sinistro {numSx}
              </Heading>
            </HStack>
            <GroupsBadge nonEditable={nonEditable} />
          </Stack>
          <FieldGroup title="Sinistro">
            <Stack width="full" spacing="6">
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl
                  isReadOnly={nonEditable}
                  id="protocollo"
                  maxW="2xs"
                >
                  <FormLabel>Protocollo </FormLabel>
                  {/* <Input
                    type="text"
                    maxLength={255}
                    //name="protocollo"
                    value={values.protocollo}
                  /> */}
                  <FormHelperText fontSize="lg">
                    {values.protocollo}
                  </FormHelperText>
                </FormControl>

                <FormControl id="numSx" maxW="2xs">
                  <FormLabel>Numero</FormLabel>
                  {/* <Input
                    type="text"
                    maxLength={255}
                    //name="numSx"
                    value={values.numSx}

                    //pattern="[A-Za-z]{3}"
                  /> */}
                  <FormHelperText fontSize="lg">{values.numSx}</FormHelperText>
                </FormControl>
                <FormControl id="DataIncarico" maxW="2xs">
                  <FormLabel>Data Incarico</FormLabel>
                  {/*  <Input
                    type="date"
                    //required
                    value={
                      `${values.annoInc}-${values.meseInc}-${values.giornoInc}` ||
                      new Date().toISOString().slice(0, 10)
                    }
                    //placeholder={`${values.giornoInc}/${values.meseInc}/${values.annoInc}`}
                    //ref={DataIncarico}
                    //pattern="^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$"
                    min="2010-01-01"
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={e => {
                      console.log(e.target.value.toString());
                      const dateTemp = e.target.value.split('-');
                      console.log(dateTemp);
                      setValues({
                        ...values,
                        annoInc: dateTemp[0],
                        meseInc: dateTemp[1],
                        giornoInc: dateTemp[2],
                      });
                    }}
                  /> */}

                  <FormHelperText
                    color="black"
                    fontSize="lg"
                  >{`${values.annoInc}-${values.meseInc}-${values.giornoInc}`}</FormHelperText>
                </FormControl>
              </HStack>
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="DataSinistro" maxW="2xs">
                  <FormLabel>Data Sinistro</FormLabel>
                  <Input
                    type="date"
                    value={
                      `${values.annoSx}-${values.meseSx}-${values.giornoSx}` ||
                      new Date().toISOString().slice(0, 10)
                    }
                    min="2010-01-01"
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={e => {
                      const dateTemp = e.target.value.split('-');
                      console.log(dateTemp);
                      setValues({
                        ...values,
                        annoSx: dateTemp[0],
                        meseSx: dateTemp[1],
                        giornoSx: dateTemp[2],
                      });
                    }}
                  />
                  {/* giornoSx meseSx annoSx */}
                </FormControl>

                <FormControl id="OraSinistro" maxW="2xs">
                  <FormLabel>Ora Sinistro</FormLabel>
                  <Input
                    type="time"
                    value={`${values.oraSx}:${values.minSx}`}
                    min="00:00"
                    max="23:59"
                    onChange={e => {
                      const timeTemp = e.target.value.toString().split(':');
                      console.log(timeTemp);
                      setValues({
                        ...values,
                        oraSx: timeTemp[0],
                        minSx: timeTemp[1],
                      });
                    }}
                  />
                  {/* oraSx minSx */}
                </FormControl>

                <FormControl id="sp" maxW="2xs">
                  <FormLabel>SP</FormLabel>
                  <Input type="text" maxLength={255} value={values.sp} />
                </FormControl>
              </HStack>
              <HStack
                justify="flex-start"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="comuneSx" maxW="3xs">
                  <FormLabel>Comune</FormLabel>
                  <Input type="text" maxLength={255} value={values.comuneSx} />
                </FormControl>

                <FormControl id="luogoSx">
                  <FormLabel>Luogo del Sinistro</FormLabel>
                  <Textarea rows={1} value={values.luogoSx} />
                </FormControl>
              </HStack>
              {/* <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input type="email" isReadOnly value="joe@chakra-ui.com" />
                </FormControl> */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="noteLuogo">
                  <FormLabel>Note sul Luogo</FormLabel>
                  <Textarea rows={2} value={values.noteLuogo} />
                </FormControl>
                <FormControl id="descrSx">
                  <FormLabel>Descrizione</FormLabel>
                  <Textarea rows={2} value={values.descrSx} />
                </FormControl>
                <FormControl id="autorita">
                  <FormLabel>Autorità Intervenute</FormLabel>
                  <Textarea rows={2} value={values.autorita} />
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>
          <FieldGroup title="Veicolo">
            <Stack width="full" spacing="6">
              {/* VStack ????????? */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="marcaMod" maxW="2xs">
                  <FormLabel>Marca e Modello</FormLabel>
                  <Input type="text" maxLength={255} value={values.marcaMod} />
                </FormControl>

                <FormControl id="targa" maxW="2xs">
                  <FormLabel>Targa</FormLabel>
                  <Input type="text" maxLength={255} value={values.targa} />
                </FormControl>
              </HStack>
              <HStack
                justify="flex-start"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="conducente" maxW="3xs">
                  <FormLabel>Conducente</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.conducente}
                  />
                </FormControl>

                <FormControl id="danni">
                  <FormLabel>Danni</FormLabel>
                  <Textarea rows={1} value={values.danni} />
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>

          <FieldGroup title="Intestatario">
            <Stack width="full" spacing="6">
              {/* VStack ????????? */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="cognomeInt" maxW="2xs">
                  <FormLabel>Cognome</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.cognomeInt}
                  />
                </FormControl>

                <FormControl id="nomeInt" maxW="2xs">
                  <FormLabel>Nome</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    autoComplete="off"
                    value={values.nomeInt}
                  />
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleInt" maxW="2xs">
                  <FormLabel>Codice Fiscale</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleInt}
                  />
                </FormControl>

                <Button
                  variant="outline"
                  iconSpacing="1"
                  leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                  onClick={onOpen}
                >
                  Calcola CF
                </Button>
              </HStack>

              <FormControl id="indirizzoInt">
                <FormLabel>Indirizzo</FormLabel>
                <Textarea rows={1} value={values.indirizzoInt} />
              </FormControl>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="comuneInt" maxW="2xs">
                  <FormLabel>Comune</FormLabel>
                  <Input type="text" maxLength={255} value={values.comuneInt} />
                </FormControl>

                <FormControl id="provInt" maxW="2xs">
                  <FormLabel>Provincia</FormLabel>
                  <Input type="text" maxLength={255} value={values.provInt} />
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="telCasaInt" maxW="2xs">
                  <FormLabel>Tel. Fisso</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.telCasaInt}
                  />
                </FormControl>

                <FormControl id="telCellInt" maxW="2xs">
                  <FormLabel>Mobile</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.telCellInt}
                  />
                  <FormHelperText>Brief description</FormHelperText>
                </FormControl>
                <FormControl id="telAltroInt" maxW="2xs">
                  <FormLabel>Altro</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.telAltroInt}
                  />
                  {/* giornoInc meseInc annoInc */}
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>

          <FieldGroup title="Legale">
            <Stack width="full" spacing="6">
              {/* VStack ????????? */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="ragSocialeLeg">
                  <FormLabel>Ragione Sociale</FormLabel>
                  <Textarea rows={1} value={values.ragSocialeLeg} />
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="nomeLeg" maxW="2xs">
                  <FormLabel>Nome</FormLabel>
                  <Input type="text" maxLength={255} value={values.nomeLeg} />
                </FormControl>

                <FormControl id="partIvaLeg" maxW="2xs">
                  <FormLabel>Partita Iva</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.partIvaLeg}
                  />
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleLeg" maxW="2xs">
                  <FormLabel>Codice Fiscale</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleLeg}
                  />
                </FormControl>

                <Button
                  variant="outline"
                  iconSpacing="1"
                  leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                  onClick={onOpen}
                >
                  Calcola CF
                </Button>
              </HStack>

              <FormControl id="indirizzoLeg">
                <FormLabel>Indirizzo</FormLabel>
                <Textarea rows={1} value={values.indirizzoLeg} />
              </FormControl>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="comuneLeg" maxW="2xs">
                  <FormLabel>Comune</FormLabel>
                  <Input type="text" maxLength={255} value={values.comuneLeg} />
                </FormControl>

                <FormControl id="provLeg" maxW="2xs">
                  <FormLabel>Provincia</FormLabel>
                  <Input type="text" maxLength={255} value={values.provLeg} />
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="telStudioLeg" maxW="2xs">
                  <FormLabel>Tel. Fisso</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.telStudioLeg}
                  />
                </FormControl>

                <FormControl id="telCellLeg" maxW="2xs">
                  <FormLabel>Mobile</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.telCellLeg}
                  />
                  <FormHelperText>Brief description</FormHelperText>
                </FormControl>
                <FormControl id="telAltroLeg" maxW="2xs">
                  <FormLabel>Altro</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.telAltroLeg}
                  />
                </FormControl>
              </HStack>
              <HStack justify="flex-end" align="baseline" /* spacing="3rem" */>
                <FormControl id="emailLeg" maxW="2xs">
                  <FormLabel>Email</FormLabel>
                  <Input type="text" maxLength={255} value={values.emailLeg} />
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>

          <FeritoGroup title="Ferito">
            <Stack width="full" spacing="6">
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="tipoFer1">
                  <FormLabel>Tipo</FormLabel>
                  <Select maxW="2xs" value={values.tipoFer1}>
                    <option value="-">-</option>
                    <option value="Conducente">Conducente</option>
                    <option value="Trasportato">Trasportato</option>
                  </Select>
                </FormControl>

                <FormControl id="visitaFer1">
                  <FormLabel>Visita medica</FormLabel>
                  <Select maxW="2xs" value={values.visitaFer1}>
                    <option value="-">-</option>
                    <option value="1">Sì</option>
                    <option value="0">No</option>
                  </Select>
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="cognomeFer1" maxW="2xs">
                  <FormLabel>Cognome</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.cognomeFer1}
                  />
                </FormControl>

                <FormControl id="nomeFer1" maxW="2xs">
                  <FormLabel>Nome</FormLabel>
                  <Input type="text" maxLength={255} value={values.nomeFer1} />
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleFer1" maxW="2xs">
                  <FormLabel>Codice Fiscale</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleFer1}
                  />
                </FormControl>

                <Button
                  variant="outline"
                  iconSpacing="1"
                  leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                  onClick={onOpen}
                >
                  Calcola CF
                </Button>
              </HStack>

              <FormControl id="legaleFer1">
                <FormLabel>Legale</FormLabel>
                <Textarea rows={1} value={values.legaleFer1} />
              </FormControl>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="partIvaLeg1" maxW="2xs">
                  <FormLabel>P.Iva Legale</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.partIvaLeg1}
                  />
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleLeg1" maxW="2xs">
                  <FormLabel>CF Legale</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleLeg1}
                  />
                </FormControl>

                <Button
                  variant="outline"
                  iconSpacing="1"
                  leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                  onClick={onOpen}
                >
                  Calcola CF
                </Button>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="telLeg1" maxW="2xs">
                  <FormLabel>Tel/Cell Legale</FormLabel>
                  <Input type="text" maxLength={255} value={values.telLeg1} />
                </FormControl>

                <FormControl id="emailLeg1" maxW="2xs">
                  <FormLabel>Email Legale</FormLabel>
                  <Input type="text" maxLength={255} value={values.emailLeg1} />
                </FormControl>
              </HStack>
            </Stack>
          </FeritoGroup>

          <FieldGroup title="Stato Sinistro">
            <Stack width="full" spacing="6">
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="franchigia">
                  <FormLabel>Stato Franchigia</FormLabel>
                  <Select maxW="2xs" value={values.franchigia}>
                    <option value="-">-</option>
                    <option value="inFranchigia">In franchigia</option>
                    <option value="fuoriFranchigia">Fuori franchigia</option>
                  </Select>
                </FormControl>

                <FormControl id="contenzioso">
                  <FormLabel>In Contenzioso</FormLabel>
                  <Select maxW="2xs" value={values.contenzioso}>
                    <option value="-">-</option>
                    <option value="1">Sì</option>
                    <option value="0">No</option>
                  </Select>
                </FormControl>
              </HStack>

              <FormControl id="noteFinali">
                <FormLabel>Ulteriori Notizie</FormLabel>
                <Textarea rows={2} value={values.noteFinali} />
              </FormControl>
            </Stack>
          </FieldGroup>

          <FieldGroup mt="4">
            <HStack width="full" justify="flex-end">
              <Button type="submit" colorScheme="blue">
                Salva
              </Button>
              <Button variant="outline">Cancel</Button>
              <Button
                variant="outline"
                onClick={() => {
                  const cssText = `
    h1 {
      color: black;
      font-family: sans-serif;
    }
  `;
                  const d = new Printd();

                  d.print(document.getElementById('settings-form'), [cssText]);
                  console.log(document.getElementById('settings-form'));
                }}
              >
                Stampa
              </Button>
            </HStack>
          </FieldGroup>
        </Stack>
      </form>
    </Box>
  );
};
