import { Input, Select, Textarea, Skeleton } from '@chakra-ui/react';
import { forwardRef } from 'react';

const InputSK = forwardRef((props, ref) => {
  const { isLoading, ...inputProps } = props;
  return (
    <Skeleton isLoaded={!isLoading}>
      <Input ref={ref} {...inputProps} />
    </Skeleton>
  );
});

const SelectSK = props => {
  const { isLoading, ...inputProps } = props;
  return (
    <Skeleton isLoaded={!isLoading}>
      <Select {...inputProps} />
    </Skeleton>
  );
};

const TextareaSK = props => {
  const { isLoading, ...inputProps } = props;
  return (
    <Skeleton isLoaded={!isLoading}>
      <Textarea {...inputProps} />
    </Skeleton>
  );
};

export { InputSK, SelectSK, TextareaSK };
