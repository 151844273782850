import { Icon, IconButton, LightMode, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';

import AlertDelete from './AlertDelete';
import { FiTrash2 } from 'react-icons/fi';

export const DeleteButton = ({ deleteFile, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <LightMode>
      <IconButton
        onClick={onOpen}
        isRound
        bg="white"
        color="gray.900"
        size="sm"
        _hover={{
          transform: 'scale(1.1)',
        }}
        sx={{
          ':hover > svg': {
            transform: 'scale(1.1)',
          },
        }}
        transition="all 0.15s ease"
        icon={
          <Icon
            as={FiTrash2}
            transition="all 0.15s ease"
            color="red"
            y="2rem"
          />
        }
        boxShadow="base"
        {...props}
      />
      <AlertDelete
        deleteFile={deleteFile}
        isOpen={isOpen}
        // leastDestructiveRef={cancelRef}
        onClose={onClose}
      />
    </LightMode>
  );
};
