import { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import SetAssignClose from './SetAssignClose';
// import { DeleteButton } from './DeleteButton';

function Actions({
  numSx,
  nomePerito,
  dataIncarico,
  dataChiusura,
  setRefresh,
  id,
  openDeleteButton,
  isAdmin,
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpenAssign,
    onClose: OnCloseAssign,
    isOpen: isOpenAssign,
  } = useDisclosure();

  /*  const {
    onOpen: onOpenDel,
    onClose: onCloseDel,
    isOpen: isOpenDel,
  } = useDisclosure(); */

  const navigate = useNavigate();
  return (
    <>
      <Popover
        trigger="click"
        isLazy={true}
        lazyBehavior="unmount"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <IconButton
            verticalAlign="middle"
            size="md"
            variant="link"
            colorScheme="blue"
            icon={<FiMenu />}
          ></IconButton>
        </PopoverTrigger>
        <PopoverContent maxW="min">
          <PopoverArrow />
          <Button
            size="sm"
            variant="ghost"
            onClick={() => navigate(`/${numSx}`)}
          >
            Visualizza
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => navigate(`/doc/${numSx}`)}
          >
            Documenti
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => navigate(`/foto/${numSx}`)}
          >
            Foto
          </Button>
          {isAdmin && (
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                //onClose();
                onOpenAssign();
              }}
            >
              Assegna/Chiudi
            </Button>
          )}
          {isAdmin && (
            <Button
              size="sm"
              color="red"
              _hover={{ bg: '#FED7D7' }}
              variant="ghost"
              onClick={() => {
                openDeleteButton(id, numSx);
              }}
            >
              Elimina
            </Button>
          )}
          {/*  <DeleteButton
            title="Elimina"
            onOpenDel={onOpenDel}
            isOpenDel={isOpenDel}
          /> */}
        </PopoverContent>
      </Popover>
      <SetAssignClose
        onClose={OnCloseAssign}
        isOpen={isOpenAssign}
        numSx={numSx}
        nomePerito={nomePerito}
        dataIncarico={dataIncarico}
        dataChiusura={dataChiusura}
        id={id}
        setRefresh={setRefresh}
      />
    </>
  );
}

export default Actions;
