import {
  Box,
  Button,
  Flex,
  HStack,
  useDisclosure,
  VisuallyHidden,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';
import { Logo } from './Logo';
import { NavLink } from './NavLink';
import { NavMenu } from './NavMenu';
import { Submenu } from './Submenu';
import { ToggleButton } from './ToggleButton';
import { links } from './_data';
import { useNavigate, useParams } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';

const MobileNavContext = props => {
  const { isOpen, onToggle } = useDisclosure();
  const { numSx } = useParams();
  const logout = useLogout();
  const navigate = useNavigate();

  const signOut = async () => {
    await logout();
    navigate('/');
  };

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        className="nav-content__mobile"
        {...props}
      >
        <Box flexBasis="6rem" visibility={!numSx ? 'hidden' : 'visible'}>
          <ToggleButton isOpen={isOpen} onClick={onToggle} />
        </Box>
        <Box as="a" rel="home" mx="auto">
          <Logo h="24px" iconColor="blue.400" />
        </Box>
        <Box
          visibility={{
            base: 'hidden',
            sm: 'visible',
          }}
        >
          {/* <Button as="a" colorScheme="blue">
            Get Started
          </Button> */}
          <Box
            px="15px"
            /* as="a"
          href="#" */
            color={mode('blue.600', 'blue.300')}
            fontWeight="bold"
            onClick={signOut}
            cursor="pointer"
          >
            Esci
          </Box>
        </Box>
      </Flex>
      <NavMenu animate={isOpen ? 'open' : 'closed'}>
        {/* {links.map((link, idx) =>
          link.children ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} href={link.href}>
              {link.label}
            </NavLink.Mobile>
          )
        )} */}
        <NavLink.Mobile to="/" onClick={onToggle}>
          Elenco
        </NavLink.Mobile>
        <NavLink.Mobile to={`/${numSx}`} onClick={onToggle}>
          Sinistro
        </NavLink.Mobile>
        <NavLink.Mobile to={`/doc/${numSx}`} onClick={onToggle}>
          Documenti
        </NavLink.Mobile>
        <NavLink.Mobile to={`/foto/${numSx}`} onClick={onToggle}>
          Foto
        </NavLink.Mobile>

        {/* <Button colorScheme="blue" w="full" size="lg" mt="5">
          Try for free
        </Button> */}
      </NavMenu>
    </>
  );
};

const DesktopNavContent = props => {
  const { numSx } = useParams();
  const logout = useLogout();
  const navigate = useNavigate();

  const signOut = async () => {
    await logout();
    navigate('/');
  };

  return (
    <Flex
      className="nav-content__desktop"
      align="center"
      justify="space-between"
      {...props}
    >
      <Box /* as="a" href="#" */ rel="home">
        <VisuallyHidden>Envelope</VisuallyHidden>
        <Logo
          h="6"
          iconColor="blue.500"
          onClick={() => navigate(`/`)}
          cursor="pointer"
        />
      </Box>
      <HStack
        as="ul"
        id="nav__primary-menu"
        aria-label="Main Menu"
        listStyleType="none"
        mx="auto"
      >
        {/*  {links.map((link, idx) => (
          <Box as="li" key={idx} id={`nav__menuitem-${idx}`}>
            {link.children ? (
              <Submenu.Desktop link={link} />
            ) : (
              <NavLink.Desktop href={link.href}>{link.label}</NavLink.Desktop>
            )}
          </Box>
        ))} */}

        {numSx ? (
          <>
            <Box as="li">
              <NavLink.Desktop to="/">Elenco</NavLink.Desktop>
            </Box>
            <Box as="li">
              <NavLink.Desktop to={`/${numSx}`}>Sinistro</NavLink.Desktop>
            </Box>
            <Box as="li">
              <NavLink.Desktop to={`/doc/${numSx}`}>Documenti</NavLink.Desktop>
            </Box>
            <Box as="li">
              <NavLink.Desktop to={`/foto/${numSx}`}>Foto</NavLink.Desktop>
            </Box>
          </>
        ) : (
          <Box h="56px" />
        )}
      </HStack>
      <HStack /* spacing="8" minW="240px" */ justify="flex-end">
        <Box
          px="15px"
          /* as="a"
          href="#" */
          color={mode('blue.600', 'blue.300')}
          fontWeight="bold"
          onClick={signOut}
          cursor="pointer"
        >
          Esci
        </Box>
      </HStack>
    </Flex>
  );
};

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent,
};
