import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Select,
  Heading,
  HStack,
  Flex,
  Spacer,
  Input,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useColorModeValue,
  VStack,
  Switch,
  Badge,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  Skeleton,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';

import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { FiMoreHorizontal, FiUsers } from 'react-icons/fi';
import { HiCloudUpload } from 'react-icons/hi';
import { FaGithub, FaGoogle } from 'react-icons/fa';
import { RiArrowRightUpLine, RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { FieldGroup } from './FieldGroup';
import { FeritoGroup } from './FeritoGroup';
import { TestimoneGroup } from './TestimoneGroup';
import CF from './CF';
import GroupsBadge from './GroupsBadge';
import { InputSK, TextareaSK } from './FieldsSK';
import CheckCheck from './CheckCheck';
import useAuth from '../../hooks/useAuth';

import { usePrompt } from './useBlocker';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { initialValues } from './initialValues';

export const App = () => {
  const { numSx } = useParams();
  const [values, setValues] = useState(initialValues);
  const inputProtoRef = useRef();
  const dateProtoRef = useRef();
  const { auth } = useAuth();

  const [nonEditable, setNonEditable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [cleanValues, setCleanValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenGroups,
    onOpen: onOpenGroups,
    onClose: onCloseGroups,
  } = useDisclosure();

  const noPrint = {
    '@media print': {
      display: 'none',
    },
  };

  useEffect(() => {
    if (location.state) {
      setNonEditable(location.state.nonEditable);
    }
    const controller = new AbortController();
    setIsLoading(true);
    const getValues = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/single_read.php?numsx=${numSx}`,
          {
            signal: controller.signal,
          }
        );
        console.log(response.data);

        setValues(response.data);
        //localStorage.setItem('sinistro', JSON.stringify(values));
        setCleanValues(JSON.stringify(response.data));
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 403) {
          navigate('/login', { state: { from: location }, replace: true });
        }
        if (err.response.status === 404) {
          //console.log(isDirty);
          //setError('404, La Pagina Richiesta non esiste ');
          navigate('/errore404', { replace: true });
        } else {
          setError('Errore, Riprova');
        }

        setIsLoading(false);
      }
    };

    getValues();

    return () => {
      //alert('oh');
      controller.abort();
    };
  }, []);

  const onChange = e => {
    setValues(prev => ({ ...prev, [e.target.id]: e.target.value }));
    //console.log(values);
    //console.log(nonEditable);
    //setIsDirty(JSON.stringify(values) !== cleanValues);
    //console.log(isDirty);
  };

  useEffect(() => {
    setIsDirty(JSON.stringify(values) !== cleanValues);
  }, [values, cleanValues]);

  const onSave = async e => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const response = await axiosPrivate.post(
        `/api/update.php`,
        JSON.stringify(values)
      );
      setIsSaving(false);
      setCleanValues(JSON.stringify(values));
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  usePrompt(
    'Vuoi Uscire dalla pagina? Le modifiche effettuate non saranno salvate',
    isDirty && !isLoading
  );

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      /* py="16" */
      py="12"
      maxWidth="4xl"
      mx="auto"
      sx={{
        '@media print': {
          paddingTop: '0',
          paddingBottom: '0',
          backgroundColor: 'white',
        },
      }}
    >
      <form id="settings-form" autoComplete="off" onSubmit={onSave}>
        <Stack spacing="4" divider={<StackDivider sx={noPrint} />}>
          <Stack sx={noPrint}>
            <HStack justify="space-between" align="center">
              <Heading size="xs" as="h3" /* paddingBottom="3" */>
                Sinistro {numSx}
              </Heading>
              {auth.role === 'admin' && (
                <HStack sx={noPrint}>
                  <FormLabel htmlFor="setEdit" m="0">
                    Modifica
                  </FormLabel>
                  <Switch
                    id="setEdit"
                    size="md"
                    value={nonEditable}
                    isChecked={!nonEditable}
                    onChange={() => setNonEditable(!nonEditable)}
                    isDisabled={isDirty}
                  />
                </HStack>
              )}
            </HStack>
            <GroupsBadge numSx={numSx} nonEditable={nonEditable} />
          </Stack>

          <FieldGroup title="Sinistro">
            <Stack width="full" spacing="6">
              {/* VStack ????????? */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl
                  isReadOnly={nonEditable}
                  id="protocollo"
                  maxW="2xs"
                >
                  <FormLabel>Protocollo </FormLabel>

                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    //name="protocollo"
                    ref={inputProtoRef}
                    value={values.protocollo?.split(' del ')[0]}
                    onChange={e => {
                      setValues({
                        ...values,
                        protocollo:
                          e.target.value +
                          ' del ' +
                          dateProtoRef.current.value
                            .split('-')
                            .reverse()
                            .join('/'),
                      });
                    }}
                  />
                </FormControl>

                <FormControl id="DataProtocollo" maxW="2xs">
                  <FormLabel>Data Protoc.</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="date"
                    ref={dateProtoRef}
                    value={
                      values.protocollo
                        .split(' del ')[1]
                        ?.trim()
                        .split('/')
                        .reverse()
                        .join('-') || ''
                    }
                    min="2010-01-01"
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={e => {
                      setValues({
                        ...values,
                        protocollo:
                          inputProtoRef.current.value +
                          ' del ' +
                          e.target.value.split('-').reverse().join('/'),
                      });
                    }}
                  />
                </FormControl>

                <FormControl id="numSx" maxW="2xs">
                  <FormLabel>Numero</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={true}
                    type="text"
                    maxLength={255}
                    title={
                      !nonEditable
                        ? 'Il Numero Sinistro non può essere Modificato'
                        : undefined
                    }
                    //name="numSx"
                    value={values.numSx}
                    onChange={onChange}
                    //pattern="[A-Za-z]{3}"
                  />
                </FormControl>
              </HStack>
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="DataIncarico" maxW="2xs">
                  <FormLabel>Data Incarico</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="date"
                    //required
                    value={
                      `${values.annoInc}-${values.meseInc}-${values.giornoInc}` ||
                      new Date().toISOString().slice(0, 10)
                    }
                    //placeholder={`${values.giornoInc}/${values.meseInc}/${values.annoInc}`}
                    //ref={DataIncarico}
                    //pattern="^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$"
                    min="2010-01-01"
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={e => {
                      console.log(e.target.value.toString());
                      const dateTemp = e.target.value.split('-');
                      console.log(dateTemp);
                      setValues({
                        ...values,
                        annoInc: dateTemp[0],
                        meseInc: dateTemp[1],
                        giornoInc: dateTemp[2],
                      });
                    }}
                  />
                </FormControl>

                <FormControl id="DataSinistro" maxW="2xs">
                  <FormLabel>Data Sinistro</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="date"
                    value={
                      `${values.annoSx}-${values.meseSx}-${values.giornoSx}` ||
                      new Date().toISOString().slice(0, 10)
                    }
                    min="2010-01-01"
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={e => {
                      const dateTemp = e.target.value.split('-');
                      console.log(dateTemp);
                      setValues({
                        ...values,
                        annoSx: dateTemp[0],
                        meseSx: dateTemp[1],
                        giornoSx: dateTemp[2],
                      });
                    }}
                  />
                  {/* giornoSx meseSx annoSx */}
                </FormControl>

                <FormControl id="OraSinistro" maxW="2xs">
                  <FormLabel>Ora Sinistro</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="time"
                    value={`${values.oraSx}:${values.minSx}`}
                    min="00:00"
                    max="23:59"
                    onChange={e => {
                      const timeTemp = e.target.value.toString().split(':');
                      console.log(timeTemp);
                      setValues({
                        ...values,
                        oraSx: timeTemp[0],
                        minSx: timeTemp[1],
                      });
                    }}
                  />
                  {/* oraSx minSx */}
                </FormControl>

                {/* <FormControl id="sp" maxW="2xs">
                  <FormLabel>SP</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.sp}
                    onChange={onChange}
                  />
                </FormControl> */}
              </HStack>
              <HStack
                justify="flex-start"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="comuneSx" maxW="3xs">
                  <FormLabel>Comune</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.comuneSx}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="luogoSx">
                  <FormLabel>Luogo del Sinistro</FormLabel>
                  <TextareaSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    //rows={values.luogoSx.length < 40 ? 1 : 2}
                    rows={Math.ceil(values.luogoSx.length / 40) || 1}
                    value={values.luogoSx}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>

              <FormControl id="descrSx">
                <FormLabel>Descrizione</FormLabel>
                <TextareaSK
                  isLoading={isLoading}
                  bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                  readOnly={nonEditable && true}
                  rows={Math.ceil(values.descrSx.length / 67) || 1}
                  value={values.descrSx}
                  onChange={onChange}
                />
              </FormControl>
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="noteLuogo">
                  <FormLabel>Note sul Luogo</FormLabel>
                  <TextareaSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    rows={Math.ceil(values.noteLuogo.length / 30) || 1}
                    value={values.noteLuogo}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="autorita">
                  <FormLabel>Autorità Intervenute</FormLabel>
                  <TextareaSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    rows={Math.ceil(values.autorita.length / 30) || 1}
                    value={values.autorita}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>

          <FieldGroup title="Veicolo">
            <Stack width="full" spacing="6">
              {/* VStack ????????? */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="marcaMod" maxW="2xs">
                  <FormLabel>Marca e Modello</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.marcaMod}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="targa" maxW="2xs">
                  <FormLabel>Targa</FormLabel>
                  <InputGroup>
                    <InputSK
                      isLoading={isLoading}
                      bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                      readOnly={nonEditable && true}
                      type="text"
                      maxLength={255}
                      value={values.targa}
                      onChange={onChange}
                    />
                    {isDirty && values.targa?.length > 5 && (
                      <CheckCheck
                        param1="targa"
                        param2=""
                        search1={values.targa}
                        search2=""
                        numSx={numSx}
                      />
                    )}
                  </InputGroup>
                </FormControl>
              </HStack>
              <HStack
                justify="flex-start"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="conducente" maxW="3xs">
                  <FormLabel>Conducente</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.conducente}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="danni">
                  <FormLabel>Danni</FormLabel>
                  <TextareaSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    rows={1}
                    value={values.danni}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>

          <FieldGroup title="Intestatario">
            <Stack width="full" spacing="6">
              {/* VStack ????????? */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="cognomeInt" maxW="2xs">
                  <FormLabel>Cognome</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.cognomeInt}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="nomeInt" maxW="2xs">
                  <FormLabel>Nome</FormLabel>
                  <InputGroup>
                    <InputSK
                      isLoading={isLoading}
                      bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                      readOnly={nonEditable && true}
                      type="text"
                      maxLength={255}
                      autoComplete="off"
                      value={values.nomeInt}
                      onChange={onChange}
                    />
                    {isDirty &&
                      values.nomeInt?.length > 2 &&
                      values.cognomeInt?.length > 2 && (
                        <CheckCheck
                          param1="cognomeInt"
                          param2="nomeInt"
                          search1={values.cognomeInt}
                          search2={values.nomeInt}
                          numSx={numSx}
                        />
                      )}
                  </InputGroup>
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleInt" maxW="2xs">
                  <FormLabel>Codice Fiscale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleInt}
                    onChange={onChange}
                  />
                </FormControl>

                {!nonEditable && (
                  <Button
                    variant="outline"
                    iconSpacing="1"
                    leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                    onClick={onOpen}
                  >
                    Calcola CF
                  </Button>
                )}
              </HStack>

              <FormControl id="indirizzoInt">
                <FormLabel>Indirizzo</FormLabel>
                <TextareaSK
                  isLoading={isLoading}
                  bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                  readOnly={nonEditable && true}
                  rows={1}
                  value={values.indirizzoInt}
                  onChange={onChange}
                />
              </FormControl>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="comuneInt" maxW="2xs">
                  <FormLabel>Comune</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.comuneInt}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="provInt" maxW="2xs">
                  <FormLabel>Provincia</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.provInt}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="telCasaInt" maxW="2xs">
                  <FormLabel>Tel. Fisso</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.telCasaInt}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="telCellInt" maxW="2xs">
                  <FormLabel>Mobile</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.telCellInt}
                    onChange={onChange}
                  />
                </FormControl>
                <FormControl id="telAltroInt" maxW="2xs">
                  <FormLabel>Altro</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.telAltroInt}
                    onChange={onChange}
                  />
                  {/* giornoInc meseInc annoInc */}
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>

          <FieldGroup title="Legale">
            <Stack width="full" spacing="6">
              {/* VStack ????????? */}
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="ragSocialeLeg">
                  <FormLabel>Ragione Sociale</FormLabel>
                  <TextareaSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    rows={1}
                    value={values.ragSocialeLeg}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="nomeLeg" maxW="2xs">
                  <FormLabel>Nome</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.nomeLeg}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="partIvaLeg" maxW="2xs">
                  <FormLabel>Partita Iva</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.partIvaLeg}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleLeg" maxW="2xs">
                  <FormLabel>Codice Fiscale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleLeg}
                    onChange={onChange}
                  />
                </FormControl>
                {!nonEditable && (
                  <Button
                    variant="outline"
                    iconSpacing="1"
                    leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                    onClick={onOpen}
                  >
                    Calcola CF
                  </Button>
                )}
              </HStack>

              <FormControl id="indirizzoLeg">
                <FormLabel>Indirizzo</FormLabel>
                <TextareaSK
                  isLoading={isLoading}
                  bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                  readOnly={nonEditable && true}
                  rows={1}
                  value={values.indirizzoLeg}
                  onChange={onChange}
                />
              </FormControl>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="comuneLeg" maxW="2xs">
                  <FormLabel>Comune</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.comuneLeg}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="provLeg" maxW="2xs">
                  <FormLabel>Provincia</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.provLeg}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="telStudioLeg" maxW="2xs">
                  <FormLabel>Tel. Fisso</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.telStudioLeg}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="telCellLeg" maxW="2xs">
                  <FormLabel>Mobile</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.telCellLeg}
                    onChange={onChange}
                  />
                </FormControl>
                <FormControl id="telAltroLeg" maxW="2xs">
                  <FormLabel>Altro</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.telAltroLeg}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>
              <HStack justify="flex-end" align="baseline" /* spacing="3rem" */>
                <FormControl id="emailLeg" maxW="2xs">
                  <FormLabel>Email</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.emailLeg}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>
            </Stack>
          </FieldGroup>

          <TestimoneGroup title="Testimone" value={values.cognomeTeste1}>
            <Stack width="full" spacing="6">
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="cognomeTeste1" maxW="2xs">
                  <FormLabel>Cognome</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.cognomeTeste1}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="nomeTeste1" maxW="2xs">
                  <FormLabel>Nome</FormLabel>
                  <InputGroup>
                    <InputSK
                      isLoading={isLoading}
                      bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                      readOnly={nonEditable && true}
                      type="text"
                      maxLength={255}
                      value={values.nomeTeste1}
                      onChange={onChange}
                    />
                    {isDirty &&
                      values.nomeTeste1?.length > 2 &&
                      values.cognomeTeste1?.length > 2 && (
                        <CheckCheck
                          param1="cognomeTeste1"
                          param2="nomeTeste1"
                          search1={values.cognomeTeste1}
                          search2={values.nomeTeste1}
                          numSx={numSx}
                        />
                      )}
                  </InputGroup>
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleTeste1" maxW="2xs">
                  <FormLabel>Codice Fiscale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleTeste1}
                    onChange={onChange}
                  />
                </FormControl>
                {!nonEditable && (
                  <Button
                    variant="outline"
                    iconSpacing="1"
                    leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                    onClick={onOpen}
                  >
                    Calcola CF
                  </Button>
                )}
              </HStack>

              <FormControl id="indirizzoTeste1">
                <FormLabel>Indirizzo</FormLabel>
                <TextareaSK
                  isLoading={isLoading}
                  bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                  readOnly={nonEditable && true}
                  rows={1}
                  value={values.indirizzoTeste1}
                  onChange={onChange}
                />
              </FormControl>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="comuneTeste1" maxW="2xs">
                  <FormLabel>Comune</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.comuneTeste1}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="provTeste1" maxW="2xs">
                  <FormLabel>Provincia</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.provTeste1}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>
            </Stack>
          </TestimoneGroup>

          <FeritoGroup title="Ferito" value={values.cognomeFer1}>
            <Stack width="full" spacing="6">
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="tipoFer1">
                  <FormLabel>Tipo</FormLabel>
                  <Select
                    disabled={nonEditable}
                    maxW="2xs"
                    value={values.tipoFer1}
                    onChange={onChange}
                  >
                    <option value="-">-</option>
                    <option value="Conducente">Conducente</option>
                    <option value="Trasportato">Trasportato</option>
                  </Select>
                </FormControl>

                <FormControl id="visitaFer1">
                  <FormLabel>Visita medica</FormLabel>
                  <Select
                    disabled={nonEditable}
                    maxW="2xs"
                    value={values.visitaFer1}
                    onChange={onChange}
                  >
                    <option value="-">-</option>
                    <option value="1">Sì</option>
                    <option value="0">No</option>
                  </Select>
                </FormControl>
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="cognomeFer1" maxW="2xs">
                  <FormLabel>Cognome</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.cognomeFer1}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="nomeFer1" maxW="2xs">
                  <FormLabel>Nome</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.nomeFer1}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleFer1" maxW="2xs">
                  <FormLabel>Codice Fiscale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleFer1}
                    onChange={onChange}
                  />
                </FormControl>
                {!nonEditable && (
                  <Button
                    variant="outline"
                    iconSpacing="1"
                    leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                    onClick={onOpen}
                  >
                    Calcola CF
                  </Button>
                )}
              </HStack>

              <FormControl id="legaleFer1">
                <FormLabel>Legale</FormLabel>
                <TextareaSK
                  isLoading={isLoading}
                  bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                  readOnly={nonEditable && true}
                  rows={1}
                  value={values.legaleFer1}
                  onChange={onChange}
                />
              </FormControl>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="partIvaLeg1" maxW="2xs">
                  <FormLabel>P.Iva Legale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.partIvaLeg1}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>

              <HStack justify="space-between" align="end" /* spacing="3rem" */>
                <FormControl id="codFiscaleLeg1" maxW="2xs">
                  <FormLabel>CF Legale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.codFiscaleLeg1}
                    onChange={onChange}
                  />
                </FormControl>
                {!nonEditable && (
                  <Button
                    variant="outline"
                    iconSpacing="1"
                    leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                    onClick={onOpen}
                  >
                    Calcola CF
                  </Button>
                )}
              </HStack>

              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="telLeg1" maxW="2xs">
                  <FormLabel>Tel/Cell Legale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.telLeg1}
                    onChange={onChange}
                  />
                </FormControl>

                <FormControl id="emailLeg1" maxW="2xs">
                  <FormLabel>Email Legale</FormLabel>
                  <InputSK
                    isLoading={isLoading}
                    bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                    readOnly={nonEditable && true}
                    type="text"
                    maxLength={255}
                    value={values.emailLeg1}
                    onChange={onChange}
                  />
                </FormControl>
              </HStack>
            </Stack>
          </FeritoGroup>

          <FieldGroup title="Stato Sinistro">
            <Stack width="full" spacing="6">
              <HStack
                justify="space-between"
                align="baseline" /* spacing="3rem" */
              >
                <FormControl id="franchigia">
                  <FormLabel>Stato Franchigia</FormLabel>
                  <Select
                    disabled={nonEditable}
                    maxW="2xs"
                    value={values.franchigia}
                    onChange={onChange}
                  >
                    <option value="-">-</option>
                    <option value="inFranchigia">In franchigia</option>
                    <option value="fuoriFranchigia">Fuori franchigia</option>
                  </Select>
                </FormControl>

                <FormControl id="contenzioso">
                  <FormLabel>In Contenzioso</FormLabel>
                  <Select
                    disabled={nonEditable}
                    maxW="2xs"
                    value={values.contenzioso}
                    onChange={onChange}
                  >
                    <option value="-">-</option>
                    <option value="1">Sì</option>
                    <option value="0">No</option>
                  </Select>
                </FormControl>
              </HStack>

              <FormControl id="noteFinali">
                <FormLabel>Ulteriori Notizie</FormLabel>
                <TextareaSK
                  isLoading={isLoading}
                  bg={nonEditable && 'var(--chakra-colors-bg-canvas)'}
                  readOnly={nonEditable && true}
                  rows={2}
                  value={values.noteFinali}
                  onChange={onChange}
                />
              </FormControl>
            </Stack>
          </FieldGroup>

          <FieldGroup mt="4">
            <HStack width="full" justify="flex-end" sx={noPrint}>
              {auth.role === 'admin' && (
                <Button
                  variant="outline"
                  isDisabled={!isDirty}
                  onClick={() => setValues(JSON.parse(cleanValues))}
                >
                  Annulla Modifiche
                </Button>
              )}
              {auth.role === 'admin' && (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSaving}
                  isDisabled={!isDirty}
                >
                  Salva
                </Button>
              )}
              <Button variant="outline" onClick={() => window.print()}>
                Stampa
              </Button>
            </HStack>
          </FieldGroup>
        </Stack>
      </form>
      <Modal /* finalFocusRef={finalRef} */ isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <CF onClose={onClose} />
      </Modal>
    </Box>
  );
};
