import { useEffect, useState } from 'react';
import {
  HStack,
  Badge,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { groups } from '../../groups';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import SetGroups from './SetGroups';

const GroupsBadge = ({ onOpenGroups, nonEditable, numSx }) => {
  /* const groups = [
    { id_groups: 7, name: 'Da Liquidare', state: false },
    { id_groups: 8, name: 'Negoziazione', state: false },
    { id_groups: 6, name: 'Contestati', state: false },
    { id_groups: 9, name: 'Causa', state: false },
    { id_groups: 12, name: 'Offerta', state: false },
    { id_groups: 13, name: 'Fuori Franchigia', state: false },
    { id_groups: 14, name: 'Sentenza', state: false },
  ]; */

  const { isOpen, onOpen, onClose } = useDisclosure();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();

    const getValues = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/read-groups.php?numsx=${numSx}`,
          {
            signal: controller.signal,
          }
        );
        console.log(response.data);

        setValues(response.data);
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 403) {
          navigate('/login', { state: { from: location }, replace: true });
        } else {
          setIsLoading(false);
          setError(true);
        }
      }
    };

    getValues();

    return () => {
      controller.abort();
    };
  }, []);

  if (error) {
    return <Alert status={error}> Errore </Alert>;
  }

  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="20px" maxW="2xs" />
      </Stack>
    );
  }

  return (
    <>
      <HStack wrap>
        {groups
          .filter(group => values.includes(group.id_groups))
          .map(group => {
            return (
              <Badge key={group.id_groups} colorScheme="blue">
                {group.name}
              </Badge>
            );
          })}

        <Button
          isDisabled={nonEditable}
          onClick={onOpen}
          //leftIcon={<Icon as={FiSettings} />}

          colorScheme="blue"
          size="xs"
        >
          <Icon as={FiMoreHorizontal} />
        </Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <SetGroups
          onClose={onClose}
          groups={groups}
          numSx={numSx}
          checkValues={values}
          setBadges={setValues}
        />
      </Modal>
    </>
  );
};

export default GroupsBadge;
