import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormLabel,
  FormControl,
  HStack,
  Select,
  Stack,
  useClipboard,
  Flex,
  Alert,
} from '@chakra-ui/react';
import { useState } from 'react';
import CodiceFiscale from 'codice-fiscale-js';
import { province } from './province';
import { FiSliders } from 'react-icons/fi';

const CF = ({ onClose }) => {
  const initialValues = {
    name: '',
    surname: '',
    gender: 'M',
    day: '01',
    month: '01',
    year: '2000',
    birthplace: '',
    birthplaceProvincia: '',
  };

  const [values, setValues] = useState(initialValues);
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const { hasCopied, onCopy } = useClipboard(code);

  const onChange = e => {
    setValues(prev => ({ ...prev, [e.target.id]: e.target.value }));
    console.log(values);
  };

  const dreamCode = () => {
    try {
      let cf = CodiceFiscale.compute(values);
      setCode(cf);
      setError(false);
    } catch (e) {
      setCode('');
      setError(true);
    }
  };

  return (
    <ModalContent>
      <ModalHeader>Calcola Codice Fiscale</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack width="full" spacing="6">
          <HStack justify="space-between" align="baseline" /* spacing="3rem" */>
            <FormControl id="name" maxW="2xs">
              <FormLabel>Nome</FormLabel>
              <Input
                type="text"
                maxLength={255}
                autoComplete="off"
                value={values.name}
                onChange={onChange}
              />
            </FormControl>

            <FormControl id="surname" maxW="2xs">
              <FormLabel>Cognome</FormLabel>
              <Input
                type="text"
                maxLength={255}
                value={values.surname}
                onChange={onChange}
                autoComplete="off"
              />
            </FormControl>
          </HStack>
          <HStack justify="space-between" align="baseline" /* spacing="3rem" */>
            <FormControl id="gender">
              <FormLabel>Sesso</FormLabel>
              <Select maxW="4.5rem" value={values.gender} onChange={onChange}>
                <option value="M">M</option>
                <option value="F">F</option>
              </Select>
            </FormControl>
            {/*  <FormControl id="birthplaceProvincia">
              <FormLabel>Stato </FormLabel>
              <Select
                maxW="xs"
                value={values.birthplaceProvincia}
                onChange={onChange}
              >
                <option value="">Italia</option>
                <option value="EE">Estero</option>
              </Select>
            </FormControl> */}
            <FormControl id="Data" maxW="2xs">
              <FormLabel>Data Nascita</FormLabel>
              <Input
                type="date"
                value={
                  `${values.year}-${values.month}-${values.day}` ||
                  new Date().toISOString().slice(0, 10)
                }
                min="1920-01-01"
                max={new Date().toISOString().slice(0, 10)}
                onChange={e => {
                  const dateTemp = e.target.value.split('-');
                  console.log(+dateTemp[2]);
                  setValues({
                    ...values,
                    year: dateTemp[0],
                    month: dateTemp[1],
                    day: dateTemp[2],
                  });
                }}
              />
            </FormControl>
          </HStack>
          <HStack justify="space-between" align="end" /* spacing="3rem" */>
            <FormControl id="birthplace" maxW="2xs">
              <FormLabel>
                {values.birthplaceProvincia === 'EE'
                  ? 'Stato di Nascita'
                  : 'Comune di Nascita'}
              </FormLabel>
              <Input
                type="text"
                maxLength={255}
                value={values.birthplace}
                onChange={onChange}
                autoComplete="off"
              />
            </FormControl>
            <FormControl id="birthplaceProvincia">
              <FormLabel>Provincia/Estero</FormLabel>
              <Select
                maxW="xs"
                value={values.birthplaceProvincia}
                onChange={onChange}
              >
                <option value=""></option>
                <option value="EE">*Estero*</option>
                {province.map((sigla, index) => (
                  <option value={sigla} key={sigla}>
                    {sigla}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <HStack justify="flex-end" align="end">
            <Button
              leftIcon={<FiSliders />}
              variant="outline"
              onClick={dreamCode}
            >
              Calcola
            </Button>
          </HStack>
          <HStack justify="space-between" align="end">
            <FormControl id="code">
              <FormLabel>Codice Fiscale</FormLabel>
              <Input
                type="text"
                maxLength={255}
                defaultValue={code}
                autoComplete="off"
              />
            </FormControl>
            <Button onClick={onCopy} ml={2}>
              {hasCopied ? 'Copiato' : 'Copia'}
            </Button>
          </HStack>
          {/* {code && (
            <Flex>
              <Input defaultValue={code} isReadOnly />
              <Button onClick={onCopy} ml={2}>
                {hasCopied ? 'Copiato' : 'Copia'}
              </Button>
            </Flex>
          )} */}
        </Stack>
      </ModalBody>

      <ModalFooter>
        {/*   <Button colorScheme="blue" mr={3} onClick={onClose}>
          Close
        </Button>
        <Button variant="ghost">Secondary Action</Button> */}
        {error && (
          <Alert status="error">
            Errore, controlla la provincia, lo Stato estero va scritto in
            italiano es: 'Francia', 'Federazione Russa' etc.
            <br /> Alcuni comuni vanno scritti in modo preciso es: 'Sant'Agata
            de' Goti'
          </Alert>
        )}
      </ModalFooter>
    </ModalContent>
  );
};

export default CF;
