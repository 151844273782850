import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get('/auth/refresh.php', {
      withCredentials: true,
    });
    setAuth(prev => {
      console.log(JSON.stringify(prev));
      console.log(response.data.accessJwt);

      return {
        ...prev,
        role: response.data.role,
        accessToken: response.data.accessJwt,
      };
    });
    return response.data.accessJwt;
  };
  return refresh;
};

export default useRefreshToken;
