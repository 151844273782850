import {
  Box,
  useDisclosure,
  Stack,
  Divider,
  Text,
  Skeleton,
  Alert,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState, useEffect } from 'react';

import Document from './Document';

import useAuth from '../../hooks/useAuth';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Upload from './Upload';
import FullImage from './FullImage';

export const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState(null);

  const [docs, setDocs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [refresh, setRefresh] = useState({});

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();

  const { numSx } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    const getDocs = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/read-doc.php?numsx=${numSx}`,
          {
            signal: controller.signal,
          }
        );
        console.log(response.data);

        setDocs(
          response.data.filter(el => el.name !== '.' && el.name !== '..')
        );
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 403) {
          navigate('/login', { state: { from: location }, replace: true });
        }
        if (err.response.status === 404) {
          //setError('404, La Pagina Richiesta non esiste ');
          navigate('/errore404', { replace: true });
        } else {
          setError('Errore, Riprova');
        }

        setIsLoading(false);
      }
    };

    getDocs();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      {error && <Alert status="error">{error}</Alert>}
      {!isOpen && (
        <Upload
          setRefresh={setRefresh}
          numSx={numSx}
          isAdmin={auth.role === 'admin'}
          docs={docs}
        />
      )}

      {!isOpen && (
        <Stack spacing={3}>
          <Divider />

          {docs?.length === 0 && (
            <Text fontSize="lg">Ancora Nessun Documento</Text>
          )}
          {isLoading && <Skeleton h="27px" mt="12px" w="25%" />}
          {docs?.map(doc => (
            <Document
              isAdmin={auth.role === 'admin'}
              key={doc.id}
              doc={doc}
              onOpen={onOpen}
              setUrl={setUrl}
              setRefresh={setRefresh}
            />
          ))}
        </Stack>
      )}
      {isOpen && <FullImage onClose={onClose} url={url} />}
    </Box>
  );
};
