import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import TabAssign from './TabAssign';
import TabClosing from './TabClosing';

function SetAssignClose({
  isOpen,
  onClose,
  numSx,
  nomePerito,
  dataIncarico,
  dataChiusura,
  id,
  setRefresh,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="3"></ModalHeader>
        <ModalCloseButton />

        <Tabs isFitted variant="enclosed">
          <TabList mb="3">
            <Tab>Perito</Tab>
            <Tab>Chiusura</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TabAssign
                onClose={onClose}
                nomePerito={nomePerito}
                dataIncarico={dataIncarico}
                id={id}
                setRefresh={setRefresh}
              />
            </TabPanel>
            <TabPanel>
              <TabClosing
                onClose={onClose}
                dataChiusura={dataChiusura}
                id={id}
                setRefresh={setRefresh}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  );
}

export default SetAssignClose;
