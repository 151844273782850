import { useRef, useState } from 'react';
import {
  Stack,
  HStack,
  Button,
  IconButton,
  Alert,
  AlertIcon,
  Progress,
  Text,
  Heading,
} from '@chakra-ui/react';
import { FiUpload, FiFolderPlus, FiXCircle, FiDownload } from 'react-icons/fi';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';

const Upload = ({ setRefresh, numSx, images, isAdmin }) => {
  const inputRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const saveFile = () => {
    //saveAs(`${process.env.REACT_APP_BASE_URL}${url}`, 'ppp');
    let htmlBlob = `<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap" rel="stylesheet">
  </head>
  <style>
    h1, a {
      font-family: 'Inter', sans-serif;
    }
    font-family: 'Inter', sans-serif;</style><h1>Foto Sinistro ${numSx}</h1><br>`;
    images.forEach(
      el =>
        (htmlBlob += `<a href="${process.env.REACT_APP_BASE_URL}${el.url}" target="_blank" >${el.name}</a><br><br>`)
    );

    var blob = new Blob([htmlBlob], {
      type: 'text/html;charset=utf-8',
    });
    saveAs(blob, `Foto Sinistro ${numSx}.html`);
  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await axiosPrivate.post(
        `${process.env.REACT_APP_BASE_URL}/api/upload.php?numsx=${numSx}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        }
      );
      setFileName('');
      setUploadPercentage(0);
      console.log(res.data);
      setRefresh({});
    } catch (err) {
      if (err.response.status === 400) {
        setErrorMessage(err.response.data.message);
      } else {
        navigate('/login', { state: { from: location }, replace: true });
      }
      console.log(err.response.status);
    }
  };

  return (
    <Stack mb="6" /* spacing="2rem" */>
      <HStack
        justify="space-between"
        align="baseline"
        /* spacing="3rem" */
      >
        <Heading size="xs" as="h3">
          Sinistro {numSx}
        </Heading>
        <form onSubmit={onSubmit}>
          {/* <FormControl id="file" maxW="2xs"> */}
          <input
            type="file"
            name="file"
            accept=".jpg, .jpeg, .png"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={e => {
              setFile(e.target.files[0]);
              setFileName(e.target.files[0].name);
            }}
          />
          {/* </FormControl> */}

          {/* <Button variant="primary" type="submit" name="submit">
            carica
          </Button> */}
        </form>
        <HStack>
          {isAdmin && (
            <Button
              variant="outline"
              size="sm"
              iconSpacing="1"
              leftIcon={<FiFolderPlus fontSize="1.25em" />}
              onClick={() => inputRef.current.click()}
            >
              Seleziona File(s)
            </Button>
          )}
          <IconButton
            isDisabled={!images?.length}
            variant="outline"
            size="sm"
            iconSpacing="1"
            title="Scarica Tutto(Links)"
            onClick={saveFile}
          >
            <FiDownload fontSize="1.25em" />
          </IconButton>
        </HStack>
      </HStack>

      {fileName && (
        <HStack>
          <Text fontSize="lg" bg="gray.200" borderRadius="5px" px="10px">
            {fileName}
          </Text>
          <Button
            variant="outline"
            size="sm"
            iconSpacing="1"
            leftIcon={<FiUpload fontSize="1.25em" />}
            type="submit"
            name="submit"
            onClick={onSubmit}
          >
            Carica
          </Button>

          <Button
            variant="outline"
            size="sm"
            iconSpacing="1"
            title="Annulla Caricamento"
            onClick={() => {
              setFileName('');
              inputRef.current.value = '';
            }}
          >
            <FiXCircle fontSize="1.25em" color="red" />
          </Button>
        </HStack>
      )}

      {uploadPercentage && (
        <Stack>
          <Progress value={uploadPercentage} />
          {/* <Text fontSize="lg">{`${uploadPercentage}%`}</Text> */}
        </Stack>
      )}

      {errorMessage && (
        <Alert status="error">
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
    </Stack>
  );
};

export default Upload;
