import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { CloseButton } from './CloseButton';
import { OpenExt } from './OpenExt';

const FullImage = ({ onClose, url }) => {
  useEffect(() => {
    window.scrollTo(0, (document.body.clientHeight - window.innerHeight) / 2);
  }, []);

  return (
    <Box position="relative">
      <AspectRatio ratio={4 / 3}>
        {/* <LinkOverlay href="#"> */}

        <Image
          src={`${process.env.REACT_APP_BASE_URL}${url}`}
          alt={'name'}
          draggable="false"
          fallback={<Skeleton />}
          borderRadius={useBreakpointValue({
            base: 'md',
            md: 'xl',
          })}
        />

        {/* </LinkOverlay> */}
      </AspectRatio>
      <CloseButton position="absolute" top="4" right="4" onClose={onClose} />
      <OpenExt position="absolute" top="16" right="4" url={url} />
    </Box>
  );
};

export default FullImage;
