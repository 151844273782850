import {
  AspectRatio,
  Box,
  Image,
  Button,
  Link,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

import { DeleteButton } from './DeleteButton';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';

export const ProductCard = props => {
  const { image, rootProps, setRefresh, isAdmin } = props;
  const { url, thumb, name } = image;

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const openSetUrl = () => {
    props.setUrl(url);
    props.onOpen();
  };

  const deleteFile = async () => {
    try {
      const res = await axiosPrivate.delete(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/delete-image.php?thumb=${encodeURIComponent(thumb)}`
      );

      console.log(res.data);
      setRefresh({});
    } catch (err) {
      if (err.response.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
    }
  };

  return (
    <Stack
      spacing={useBreakpointValue({
        base: '2',
        md: '3',
      })}
      {...rootProps}
    >
      {/* <LinkBox> */}
      <Box position="relative">
        <AspectRatio ratio={4 / 3}>
          {/* <LinkOverlay href="#"> */}

          <Image
            onClick={openSetUrl}
            cursor="pointer"
            src={`${process.env.REACT_APP_BASE_URL}${thumb}`}
            alt={name}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius={useBreakpointValue({
              base: 'md',
              md: 'xl',
            })}
          />

          {/* </LinkOverlay> */}
        </AspectRatio>
        {isAdmin && (
          <DeleteButton
            position="absolute"
            top="4"
            right="4"
            deleteFile={deleteFile}
          />
        )}
      </Box>
      {/*  </LinkBox> */}

      <Stack spacing="1">
        <Text
          fontWeight="medium"
          color={useColorModeValue('gray.700', 'gray.400')}
        >
          {name}
        </Text>
        <Button
          as="a"
          href={url}
          download
          //href={`/home/cldben5/public_html/new_back${url}`}
          //href={`https://cldbenevento.com/new_back${url}`}
          //download
          //onClick={saveFile}
          //textDecoration="underline"
          fontWeight="medium"
          fontSize="sm"
          color={useColorModeValue('gray.600', 'gray.400')}
        >
          Scarica
        </Button>
      </Stack>
      {/* <HStack>
          <Rating defaultValue={rating} size="sm" />
          <Text fontSize="sm" color={useColorModeValue('gray.600', 'gray.400')}>
            12 Reviews
          </Text>
        </HStack> */}

      {/* <Stack align="center">
      
        <Link
          textDecoration="underline"
          fontWeight="medium"
          color={useColorModeValue('gray.600', 'gray.400')}
        >
          Quick shop
        </Link>
      </Stack> */}
    </Stack>
  );
};
