import { useEffect, useState } from 'react';
import './spinner.css';
import { InputRightAddon, InputRightElement } from '@chakra-ui/react';
import { FiSearch, FiRefreshCw, FiAlertCircle, FiUsers } from 'react-icons/fi';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const CheckCheck = ({ param1, param2, search1, search2, numSx }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [exists, setExists] = useState(null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setExists(null);
  }, [search1, search2]);

  const checkIfExists = async () => {
    const controller = new AbortController();
    setError(false);
    setExists(null);
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(
        `/api/exists.php?param1=${param1}&param2=${param2}`,
        JSON.stringify({ search1, search2, numSx }),
        {
          signal: controller.signal,
        }
      );
      console.log(response.data);
      setExists(response.data.trovati);
      setIsLoading(false);
    } catch (err) {
      if (err.response.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      setError(true);
      setExists(null);
      setIsLoading(false);
    }
  };

  return (
    <InputRightElement>
      {isLoading ? (
        <FiRefreshCw className="spinner" />
      ) : exists !== null ? (
        <FiUsers
          color={exists === 0 ? 'green' : 'red'}
          title={
            exists === 0
              ? 'non presente in archivio '
              : `presente ${exists} volte in archivio`
          }
        />
      ) : (
        <FiSearch
          cursor="pointer"
          onClick={checkIfExists}
          title="controlla se già presente in archivio "
        />
      )}

      {error && <FiAlertCircle color="red" title="errore di rete" />}
    </InputRightElement>
  );
};

export default CheckCheck;
