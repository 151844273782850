import { Box, Heading } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { TableActions } from './TableActions';
import { TableContent } from './TableContent';
import { TableSkeleton } from './TableSkeleton';
import { TablePagination } from './TablePagination';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { useNavigate, useLocation } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

export const App = () => {
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [refresh, setRefresh] = useState({});
  const [error, setError] = useState('');

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [params, setParams] = useState({
    search: '',
    group: '',
    limit: localStorage.getItem('perPageCB') || '12',
    orderBy: '',
    noDesc: '',
    offset: '',
  });

  const { search, group, limit, orderBy, noDesc, offset } = params;

  let URL = `/api/search.php?search=${search}&group=${group}&limit=${limit}&orderBy=${orderBy}&noDesc=${noDesc}&offset=${offset}`;

  useEffect(() => {
    setIsLoading(true);

    const controller = new AbortController();

    const getUsers = async () => {
      try {
        // '/api/search.php?limit=10'
        const response = await axiosPrivate.get(
          /* '/api/search.php?search=&group=&limit=12&orderBy=&noDesc=&offset=' */ URL,
          {
            signal: controller.signal,
          }
        );
        console.log(response.data.body);

        setData(response.data.body);
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 403) {
          navigate('/login', { state: { from: location }, replace: true });
        }
        if (err.response.status === 404) {
          //setError('404, La Pagina Richiesta non esiste ');
          navigate('/errore404', { replace: true });
        } else {
          setError('Errore, Riprova');
        }

        setIsLoading(false);
      }
    };

    getUsers();

    return () => {
      controller.abort();
    };
  }, [refresh, URL]);

  return (
    <Box as="section" py="12">
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: '6',
          md: '8',
        }}
      >
        <Box overflowX="auto">
          {/* <Heading size="lg" mb="6">
            Contact
          </Heading> */}
          <TableActions
            isAdmin={auth.role === 'admin'}
            isLoading={isLoading}
            setParams={setParams}
          />
          {/* {isLoading ? (
            <TableSkeleton />
          ) : (
            <TableContent data={data} setRefresh={setRefresh} />
          )} */}
          <TableContent
            data={data}
            setRefresh={setRefresh}
            isLoading={isLoading}
            params={params}
            setParams={setParams}
            isAdmin={auth.role === 'admin'}
          />
          <TablePagination
            setParams={setParams}
            isLoading={isLoading}
            dataLength={data.length}
            offset={offset}
          />
          {/* <button
            onClick={() => {
              setIsLoading(true);
              setRefresh({});
            }}
          >
            REFRESH
          </button>
          <button onClick={signOut}>Sign Out</button>
          <button onClick={() => setParams(prev => ({ ...prev, limit: 5 }))}>
            CHANGEPRM
          </button> */}
        </Box>
      </Box>
    </Box>
  );
};
