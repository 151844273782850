let groups = [];

if (process.env.REACT_APP_SIGLA === 'CB') {
  groups = [
    { id_groups: 7, name: 'Da Liquidare', state: false },
    { id_groups: 8, name: 'Negoziazione', state: false },
    { id_groups: 6, name: 'Contestati', state: false },
    { id_groups: 9, name: 'Causa', state: false },
    { id_groups: 12, name: 'Offerta', state: false },
    { id_groups: 13, name: 'Fuori Franchigia', state: false },
    { id_groups: 14, name: 'Sentenza', state: false },
  ];
}

if (process.env.REACT_APP_SIGLA === 'RO') {
  groups = [
    { id_groups: 4, name: 'Causa', state: false },
    { id_groups: 7, name: 'Contestati', state: false },
    { id_groups: 1, name: 'Fuori Franchigia', state: false },
    { id_groups: 6, name: 'Mediazione', state: false },
    { id_groups: 5, name: 'Negoziazione Assistita', state: false },
    { id_groups: 9, name: 'Offerta', state: false },
    { id_groups: 2, name: 'Sentenza', state: false },
    { id_groups: 8, name: 'Transatte', state: false },
  ];
}

export { groups };
