import { chakra, useColorModeValue as mode, Link } from '@chakra-ui/react';

import { NavLink as RouterNavLink } from 'react-router-dom';

import * as React from 'react';
const DesktopNavLink = React.forwardRef((props, ref) => {
  const { active, ...rest } = props;

  return (
    <Link
      as={RouterNavLink}
      /* ref={ref} */
      display="inline-block"
      px="4"
      py="4"
      fontWeight="semibold"
      outline="0px"
      /* aria-current={active} */
      color={mode('gray.600', 'gray.400')}
      //transition="all 0.2s"
      {...rest}
      _hover={{
        color: 'gray.500',
      }}
      /*  _active={{
        color: 'blue.600',
      }} */
      _activeLink={{
        color: 'blue.600',
        fontWeight: 'bold',
        outline: '0px',
        borderBottom: '2px',
        marginBottom: '-2px',
      }}
    />
  );
});
DesktopNavLink.displayName = 'DesktopNavLink';

export const MobileNavLink = props => {
  const { active, ...rest } = props;
  return (
    // <chakra.a
    <Link
      as={RouterNavLink}
      aria-current={active ? 'page' : undefined}
      w="full"
      display="flex"
      alignItems="center"
      height="14"
      fontWeight="semibold"
      //borderBottomWidth="1px"
      _activeLink={{
        color: 'blue.600',
        fontWeight: 'bold',
      }}
      {...rest}
    />
  );
};
export const NavLink = {
  Mobile: MobileNavLink,
  Desktop: DesktopNavLink,
};
