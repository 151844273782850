import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
//import AuthContext from '../../context/AuthProvider';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import axios from '../../api/axios';

import { Logo } from './Logo';
import { HiEye, HiEyeOff } from 'react-icons/hi';

const LOGIN_URL = '/auth/login.php';

export const App = React.forwardRef((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef(null);
  const mergeRef = useMergeRefs(inputRef, ref);

  const { setAuth, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [remember, setRemember] = useState(true);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ userName: user, password: pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response));
      const accessToken = response?.data?.accessJwt;
      const role = response?.data?.role;
      setAuth({ user, pwd, role, accessToken });
      setPersist(remember);
      localStorage.setItem('persist', remember);
      setUser('');
      setPwd('');
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('Nessuna Risposta');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Credenziali Errate o Scadute');
      } else {
        setErrMsg('Accesso Non Riuscito');
      }
      //errRef.current.focus();
      //setPwd('');
    }
  };

  const onClickReveal = () => {
    onToggle();

    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  };

  /* const togglePersist = () => {
    setPersist(prev => !prev);
  };

  useEffect(() => {
    console.log(persist);
    localStorage.setItem('persist', persist);
  }, [persist]); */

  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            <Heading
              size={useBreakpointValue({
                base: 'xs',
                md: 'sm',
              })}
            >
              {/* Log in to your account  */}
              Centro Liquidazione Danni
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="muted">
                Loss Adjusting Service - {process.env.REACT_APP_ENTE}{' '}
              </Text>
              {/* <Button variant="link" colorScheme="blue">
                Sign up
              </Button> */}
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={useBreakpointValue({
            base: 'transparent',
            sm: 'bg-surface',
          })}
          boxShadow={{
            base: 'none',
            sm: useColorModeValue('md', 'md-dark'),
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <Stack spacing="6">
            {/* <p
              ref={errRef}
              className={errMsg ? 'errmsg' : 'offscreen'}
              aria-live="assertive"
            >
              {errMsg}
            </p> */}

            {errMsg && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>{errMsg}</AlertTitle>
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="utente">Utente</FormLabel>
                  <Input
                    id="utente"
                    type="text"
                    ref={userRef}
                    autoComplete="off"
                    onChange={e => setUser(e.target.value)}
                    value={user}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <InputGroup>
                    <InputRightElement>
                      <IconButton
                        variant="link"
                        aria-label={
                          isOpen ? 'Mask password' : 'Reveal password'
                        }
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                      />
                    </InputRightElement>
                    <Input
                      id="password"
                      ref={mergeRef}
                      name="password"
                      type={isOpen ? 'text' : 'password'}
                      autoComplete="current-password"
                      // added
                      onChange={e => setPwd(e.target.value)}
                      value={pwd}
                      required
                      // end added
                      {...props}
                    />
                  </InputGroup>
                </FormControl>
                {/* </Stack> */}

                <HStack justify="space-between">
                  <Checkbox
                    defaultIsChecked
                    onChange={() => setRemember(prev => !prev)}

                    /* isChecked={persist} */
                  >
                    Ricordami
                  </Checkbox>
                </HStack>
                {/* <Stack spacing="6"> */}
                <Button variant="primary" type="submit">
                  Accedi
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
});
//PasswordField.displayName = 'PasswordField';
