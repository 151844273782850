export const groups = {
  1: 'Senza Seguito',
  2: 'Fuori Franchigia',
  3: 'In Contenzioso',
  5: 'Concordati',
  6: 'Liquidati',
  7: 'Contestati',
  8: 'Rinuncia al risarcimento',
  12: 'Offerta Inviata',
};

export const res = [1, 3, 12];

//ritorna l'array dei gruppi da visualizzare in form
const elements = [];

Object.entries(groups).forEach(([key, value]) => {
  if (res.includes(+key)) {
    elements.push(value);
  }
});

/* const groups = ["causa", "danno", "torta", "mare", "popopo"]

const res = [1,4]

const elements = groups.filter((_, index) => res.includes(index)) */
